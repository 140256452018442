import React, {useContext, useEffect, useState} from 'react';
import 'materialize-css';
import {useHttp} from "../hooks/http.hook";
import {useMessage} from "../hooks/message.hook";
import {AuthContext} from "../context/auth.context";
import {useCallback} from "react";


export const CreatePage = () =>{
    const {userType} = useContext(AuthContext);
    const message = useMessage();
    const {loading,error, request,clearError} = useHttp();
    const [form, setform] = useState({
        name:"",
        bundleIdIOS:"",
        bundleIdAndroid: "",
    })

    const createHandler =async e =>{
        e.preventDefault();

        if(form.name === "")
        {
            message("name is Empty");
            return;
        }

        try {

            const data = await  request('/api/game/add', 'POST', {...form},true);

            message(data.message);
        }catch (e) {

        }
    }


    const changeHandler = event =>
    {
        setform({...form, [event.target.name]:event.target.value})
    }


    useEffect(()=>{
        window.M.AutoInit();
        window.M.updateTextFields();
    },[])


    return(
        <div>
            <h5>Create Game Page</h5>
            <div className="row">
                <form className="col offset-m3 m6 s12">

                    <div className="input-field" style={{marginTop:'2rem'}}>
                        <input placeholder="Enter new game name"
                               id="name"
                               type="text"
                               name="name"
                               value={form.name}
                               onChange={changeHandler}/>
                        <label htmlFor="name">Name</label>
                    </div>
                    <div className="input-field" style={{marginTop:'2rem'}}>
                        <input placeholder="New Game IOS Bundle Id"
                               id="bundleIdIOS"
                               type="text"
                               name="bundleIdIOS"
                               value={form.bundleIdIOS}
                               onChange={changeHandler}/>
                        <label htmlFor="bundleIdIOS"> IOS Bundle Id</label>
                    </div>
                    <div className="input-field" style={{marginTop:'2rem'}}>
                        <input placeholder="New Game Android Bundle Id"
                               id="bundleIdAndroid"
                               type="text"
                               name="bundleIdAndroid"
                               value={form.bundleIdAndroid}
                               onChange={changeHandler}/>
                        <label htmlFor="bundleIdIOS"> IOS Android Id</label>
                    </div>
                    <div className="card-action">
                        <button className="btn green lighten-1 black-text"
                                onClick={createHandler}
                                disabled={loading}
                        >Create</button>
                    </div>
                </form>

            </div>
        </div>
    )
}
import React, {useState} from 'react';
import {useCallback, useContext, useEffect} from "react";
import {useStats} from "../../hooks/stats.hook";
import {DailyRevenueChart} from "./Widgets/DailyRevenueChart";


export const Monetization = ({searchQuery, globalFilters}) =>
{
    const {getMonetizationOverview} = useStats();
    const [overviewStats, setOverviewStats] = useState({});
    const [globalData, setGlobalData] = useState({
        dayAxis: {
            labels:[],
            dates:[]
        }
    });


    const DoQuery = useCallback(async ()=>{

        if(!searchQuery.gameId || !searchQuery.dateFrom || !searchQuery.dateTo) return;

        const stats = await  getMonetizationOverview(searchQuery, globalFilters);
        console.log(stats);
        setOverviewStats(stats);

    },[searchQuery,globalFilters]);

    useEffect(()=>{
        DoQuery();
    },[,searchQuery,globalFilters]);


    useEffect(()=>{
        DoProcessing();
    },[overviewStats]);


    const DoProcessing =()=>
    {
        UpdateDayAxis();
    }


    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const UpdateDayAxis = ()=>{

        const startDay = new Date(searchQuery.dateFrom);
        const endDay = new Date(searchQuery.dateTo);

        let dayAxis = {
            labels:[],
            dates:[]
        };

        for (let d = startDay; d <= endDay; d.setDate(d.getDate() + 1)) {
            let newDate = new Date(d);
            dayAxis.dates.push(newDate);
            dayAxis.labels.push(newDate.getDate() + " "+ months[newDate.getMonth()]);
        }

        setGlobalData({...globalData,dayAxis: dayAxis});

    }


    return(
        <>
            <div className="row">.
                <div className="col m6 s12">
                    <DailyRevenueChart globalData={globalData} statsData={overviewStats}/>
                </div>
            </div>

        </>
    )

}
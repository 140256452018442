import React, {useState} from 'react';
import {useCallback, useContext, useEffect} from "react";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line,Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const NewUsersChart = ({globalData,statsData}) =>
{

    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: 'New Users',
            },
        },
    };

    const [chartData, setChartData] = useState({});


    const GetNewUsersData =()=>
    {
        let newUsersData = [];

        for (let i=0; i<globalData.dayAxis.dates.length;i++) {
            newUsersData.push({
                date: globalData.dayAxis.dates[i],
                value: 0
            })
        }

        if(!statsData.newUsers || newUsersData.length === 0) return;

        for (let i=0;i<statsData.newUsers.length;i++)
        {
            let installDate = new Date(statsData.newUsers[i].date);
            installDate.setHours(0,0,0);
            let index = newUsersData.findIndex(el => el.date.getTime() === installDate.getTime());

            newUsersData[index].value = statsData.newUsers[i].count;
        }

        let dataSet = [];
        for (let i=0; i<newUsersData.length;i++){
            dataSet.push(newUsersData[i].value);
        }

        return dataSet;
    }


    const UpdateChart = ()=>{
        setChartData({
            labels:globalData.dayAxis.labels,
            datasets: [
                {
                    label: 'New Users',
                    data: GetNewUsersData(),
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                }
            ],
        });
    }

    useEffect(()=>{
        UpdateChart();
    },[globalData,statsData]);



    return (
        <>
            .
            {chartData && chartData.datasets?(<Line options={options} data={chartData} />):""}
        </>
    )
}
import React, {useState, useMemo,useContext} from 'react';
import { useTable, useSortBy, usePagination, useGlobalFilter,useFilters,useFlexLayout } from "react-table";
import {useHttp} from "../hooks/http.hook";
import {useMessage} from "../hooks/message.hook";
import {useNavigate} from "react-router-dom";


export const GamesList = ({games}) =>
{
    const {loading, request} = useHttp();
    const message = useMessage();
    const navigate = useNavigate();


    const columnProps = [
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "IOS Bundle Id",
            accessor: "bundleIdIOS",
        },
        {
            Header: "Android Bundle Id",
            accessor: "bundleIdAndroid",
        },
        {
            Header: "Action",
            accessor: "action",
            Cell: cellProps => {
                return <CustomActionCell {...cellProps} />;
            },

        }
    ];


    const openGame = (gameLink) => {
        navigate(gameLink);
    }


    function CustomActionCell(cellProps) {
        return (
            <>
                <div className="row " style={{marginBottom:0}}>
                    <div className="col s12 m6">
                        <button className="btn waves-effect waves-light orange"
                                onClick={() => openGame(`/details/${cellProps.row.original._id}`)}
                        >
                            <i className="material-icons">open_in_new</i>
                        </button>
                        {/*<Link to={`/profile/${cellProps.row.original._id}`}>Open</Link>*/}
                    </div>
                    <div className="col s12 m6">
                        <button className="btn waves-effect waves-light green"
                                onClick={() => openGame(`/abtests/${cellProps.row.original._id}`)}
                        >
                            A/B
                        </button>
                        {/*<Link to={`/profile/${cellProps.row.original._id}`}>Open</Link>*/}
                    </div>

                </div>


            </>
        );
    }


    const columns = useMemo(
        () => columnProps,
        []
    );


    const data = useMemo(
        () => games,
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        prepareRow,
        state,
        setGlobalFilter
    } = useTable({
        columns,
        data,
    },useFilters,useGlobalFilter,useSortBy,usePagination);

    const {globalFilter} = state;

    function SelectColumnFilter({
                                    column: { filterValue, setFilter, preFilteredRows, id },
                                }) {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = React.useMemo(() => {
            const options = new Set()
            preFilteredRows.forEach(row => {
                options.add(row.values[id])
            })
            return [...options.values()]
        }, [id, preFilteredRows])

        // Render a multi-select box
        return (
            <select
                value={filterValue}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
                className="browser-default"
            >
                <option value="">All</option>
                {options.map((option, i) => (
                    <option key={i} value={option}>{option}
                    </option>
                ))}
            </select>
        )
    }

    const cellProps = (props, { cell }) => getStyles(props, cell.column.align)

    const getStyles = (props, align = 'left') => [
        props,
        {
            style: {
                overflow:'initial'
                // justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
                // alignItems: 'flex-start',
                // display: 'flex',
            },
        },
    ]

    return(

        <>
            <div className="card material-table">

                <div className="table-header">
                    <span className="table-title">All Games</span>
                    <div className="actions">
                        <input value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}/>
                        <span><i className="material-icons">search</i></span>
                    </div>
                </div>

                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map((headerGroup,index) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column,i) => (
                                <th key={i}>
                                    <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render("Header")}
                                        <span>{column.isSorted? (column.isSortedDesc? '↓':'↑'):''}</span>
                                    </div>

                                    <div>
                                        {(column.canFilter && column.Filter) ? column.render("Filter"):null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={i}>
                                {row.cells.map((cell,j) => {
                                    return <td  key={j} {...cell.getCellProps(cellProps)}>{cell.render("Cell")}</td>;
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                <div className="pagination table-footer">

                    <label>
                        Page {' '}
                        <label>
                            {pageIndex + 1} of {pageOptions.length}
                        </label>{' '}
                    </label>
                    <select
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                    <ul className="material-pagination">
                        <li className="paginate_button">
                            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                <i className="material-icons">chevron_left</i>
                            </button>
                        </li>
                        <li className="paginate_button">
                            <button onClick={() => nextPage()} disabled={!canNextPage}>
                                <i className="material-icons">chevron_right</i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

        </>

    )
}
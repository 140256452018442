import {useState, useCallback, useContext,useEffect} from 'react';
import {useHttp} from "./http.hook";


export const useRemoteConfig = () => {
    const {request} = useHttp();

    const createConfig = useCallback(async (gameId, form)=>{
        try{
            form.gameId = gameId;

            const data = await request("/api/remoteConfig/createConfig","POST", {form},true);

            return data;
        }catch (e) {

        }
    },[request]);


    const getAllRemoteConfigs = useCallback(async (gameId)=>{
        try{
            const data = await request("/api/remoteConfig/getAllConfigs","POST", {gameId},true);

            return data;
        }catch (e) {

        }
    },[request]);

    return  {getAllRemoteConfigs, createConfig}
}
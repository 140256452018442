import React, {useState} from 'react';
import {useCallback, useContext, useEffect} from "react";
import {BatchEditView} from "./BatchEditView";
import {useBatch} from "../../hooks/batch.hook";
import {useRemoteConfig} from "../../hooks/remoteConfig.hook";


export const BatchesView = ({gameId}) =>
{
    const [batches,setBatches] = useState([]);
    const [remoteConfigsList, setRemoteConfigsList] = useState([]);


    const {getAllBatches} = useBatch();
    const {getAllRemoteConfigs} = useRemoteConfig();


    const [currentBatch,setCurrentBatch] = useState({
        currentBatchId:null,
        draftBatch:false,
        name:"",
        batchStatus:"development",
        countries:[],
        countriesAll:false,
        userGroups:[]
    });


    const fetchAllBatches = async  () =>{
        let data = await getAllBatches(gameId);

        setBatches(data);
    }

    const fetchRemoteConfigs = async  () =>{
        let data = await getAllRemoteConfigs(gameId);

        setRemoteConfigsList(data);
    }


    const batchSelectChangeHandler = e => {

        let batchId = e.target.value;
        if(batchId === "draft")
        {
            setCurrentBatch({...currentBatch, currentBatchId:batchId})
        }
        else
        {
            let serverBatch = batches.find(b=> b._id === batchId);

            let userGroups = [];

            for (let i=0;i<serverBatch.userGroups.length;i++)
            {
                let newObj = {};

                newObj.name = serverBatch.userGroups[i].name;
                newObj.percentage = serverBatch.userGroups[i].percentage;

                newObj.configOverwrites = [];

                for (let j=0;j<serverBatch.userGroups[i].configValueOverwrites.length;j++)
                {
                    let remoteConfigId = serverBatch.userGroups[i].configValueOverwrites[j].remoteConfig;

                    let remoteConfig = remoteConfigsList.find(c => c._id === remoteConfigId);

                    let configOverwrite = {
                        remoteConfigId:remoteConfigId,
                        configType:remoteConfig.configType,
                        defaultValue:remoteConfig.defaultValue,

                        valueOverwrite:serverBatch.userGroups[i].configValueOverwrites[j].valueOverwrite,

                        boolNewValue:false,
                        doubleNewValue:0,
                        intNewValue:0,
                        stringNewValue:"",
                    };

                    configOverwrite = applyValueOverwrite(configOverwrite);

                    newObj.configOverwrites.push(configOverwrite)
                }

                userGroups.push(newObj);
            }

            setCurrentBatch({...currentBatch,
                currentBatchId:batchId,
                draftBatch: false,
                batchStatus:serverBatch.batchStatus,
                name:serverBatch.name,
                countries: serverBatch.countries_only,
                userGroups: userGroups
            })
        }

    }


    const applyValueOverwrite = (configOverwrite) =>{
        switch (configOverwrite.configType)
        {
            case "bool":
                configOverwrite.boolNewValue = configOverwrite.valueOverwrite === "true";
                break;
            case "double":
                configOverwrite.doubleNewValue = parseFloat(configOverwrite.valueOverwrite);
                break;
            case "int":
                configOverwrite.intNewValue = parseInt(configOverwrite.valueOverwrite);
                break;
            case "string":
                configOverwrite.stringNewValue =  configOverwrite.valueOverwrite;
                break;
            default: break;
        }

        return configOverwrite;
    }

    const createHandler =async e =>{
        e.preventDefault();

        let newDraftBatch  = {
            draftBatch:true,
            name:"Draft",
            _id:"draft",
            currentBatchId:"draft",
            countries:["US"],
            batchStatus:"development",
            countriesAll:false,
            userGroups:[{
                name:"control",
                percentage:100,
                configOverwrites:[]
            }]
        }

        batches.unshift(newDraftBatch);
        setBatches(batches);
        setCurrentBatch(newDraftBatch);
    }



    useEffect(()=>{
        window.M.AutoInit();
        window.M.updateTextFields();

        fetchAllBatches();
        fetchRemoteConfigs();
    },[])

    return(
        <>
            <div className="row" style={{marginTop:"2rem"}}>
                <select className="browser-default col s6" style={{width:"281px"}} value={currentBatch.currentBatchId} onChange={batchSelectChangeHandler}>
                    <option value="-1">Select Batch</option>
                    {batches && batches.length> 0 && batches.map(function(batch, i){
                        return (
                            <option value={batch._id}>{batch.name} - {batch.batchStatus}</option>
                        );
                    })}
                </select>

                <div className="col s6">
                    <button className="btn green lighten-1 black-text"
                            onClick={createHandler}
                    >Create</button>
                </div>
            </div>

            <BatchEditView currentBatch={currentBatch} setCurrentBatch={setCurrentBatch}  gameId={gameId} remoteConfigsList={remoteConfigsList}/>

        </>
    )
}
import React, {useState} from 'react';
import {useCallback, useContext, useEffect} from "react";
import {useMessage} from "../../hooks/message.hook";
import {useRemoteConfig} from "../../hooks/remoteConfig.hook";


export const CreateNewConfig = ({gameId}) =>
{
    const message = useMessage();
    const {createConfig} = useRemoteConfig();



    const [form, setform] = useState({
        key:"",
        type:"bool",

        boolDefaultValue:false,
        doubleDefaultValue:0,
        intDefaultValue:0,
        stringDefaultValue:"",
    })

    const changeHandler = event =>
    {
        setform({...form, [event.target.name]:event.target.value})
    }

    const intValueChangeHandler = event =>
    {
        setform({...form, [event.target.name]:Math.floor(event.target.value)})
    }
    const boolValueChangeHandler = event =>
    {
        let newValue = !form[event.target.name];

        setform({...form, [event.target.name]:newValue, defaultValue:newValue});
    }


    const createHandler =async e =>{
        e.preventDefault();

        if(form.key === "")
        {
            message("key is Empty");
            return;
        }
        if(form.type === "")
        {
            message("type is Empty");
            return;
        }
        if(form.defaultValue === "")
        {
            message("defaultValue is Empty");
            return;
        }

        switch (form.type)
        {
            case "bool":
                form.defaultValue = form.boolDefaultValue;
                break;
            case "double":
                form.defaultValue = form.doubleDefaultValue;
                break;
            case "int":
                form.defaultValue = form.intDefaultValue;
                break;
            case "string":
                form.defaultValue = form.stringDefaultValue;
                break;
        }

        try {
            
            const data = await createConfig(gameId,form);

            message(data.message);
        }catch (e) {

        }
    }


    useEffect(()=>{
        window.M.AutoInit();
        window.M.updateTextFields();
    },[])


    const drawCreateConfigTable = () =>{

        return (
            <>
                <div className="section">
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                <div className="input-field">
                                    <input placeholder="Enter new key name"
                                           id="key"
                                           type="text"
                                           name="key"
                                           value={form.key}
                                           onChange={changeHandler}/>
                                    <label htmlFor="key">Key</label>
                                </div>
                            </td>
                            <td>
                                <div className="input-field">
                                	<select
                                		name="type"
                                        value={form.type}
                                        onChange={changeHandler}
                                	>
                                		<option value="bool">Bool</option>
                                		<option value="double">Double</option>
                                        <option value="int">Int</option>
                                        <option value="string">String</option>
                                	</select>
                                	<label  htmlFor="newKeyType">New Key Type</label>
                                </div>

                            </td>
                            <td>
                                <div className="input-field"  style={{display:(form.type==="bool"?"block":"none")}}>
                                    <label className="input-field col">
                                        <input type="checkbox"
                                               id="boolDefaultValue"
                                               name ="boolDefaultValue"
                                               checked={form.boolDefaultValue ? "checked" : ""}
                                               onChange={boolValueChangeHandler}
                                               className="filled-in"/>
                                        <span>Default Value</span>
                                    </label>
                                    
                                </div>
                                <div className="input-field"  style={{display:(form.type==="double"?"block":"none")}}>
                                    <input placeholder="Default double Value"
                                           id="doubleDefaultValue"
                                           type="number"
                                           name="doubleDefaultValue"
                                           value={form.doubleDefaultValue}
                                           onChange={changeHandler}/>
                                    <label htmlFor="doubleDefaultValue">Default Value</label>
                                </div>
                                <div className="input-field"  style={{display:(form.type==="int"?"block":"none")}}>
                                    <input placeholder="Default int Value"
                                           id="intDefaultValue"
                                           type="number"
                                           name="intDefaultValue"
                                           value={form.intDefaultValue}
                                           onChange={intValueChangeHandler}/>

                                    <label htmlFor="intDefaultValue">Default Value</label>
                                </div>
                                <div className="input-field"  style={{display:(form.type==="string"?"block":"none")}}>
                                    <input placeholder="Default string Value"
                                           id="stringDefaultValue"
                                           type="text"
                                           name="stringDefaultValue"
                                           value={form.stringDefaultValue}
                                           onChange={changeHandler}/>
                                    <label htmlFor="stringDefaultValue">Default Value</label>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="card-action">
                        <button className="btn green lighten-1 black-text"
                                onClick={createHandler}
                        >Create</button>
                    </div>
                </div>
            </>
        )
    }

    return(
        <>
            {drawCreateConfigTable()}
        </>
    )
}
import React, {useState} from 'react';
import {useCallback, useContext, useEffect} from "react";
import {UserGroupConfigOverwrites} from "./UserGroupConfigOverwrites";



export const UserGroupsView = ({gameId, currentBatch, setCurrentBatch ,userGroupUpdated, remoteConfigsList}) =>
{

    const createHandler =async e =>{
        e.preventDefault();

        let newGroup  = {
            name:"new group",
            percentage:0,
            configOverwrites:[]
        }

        setCurrentBatch({...currentBatch,userGroups:[
                ...currentBatch.userGroups,
                newGroup
            ]});
    }

    const userGroupChangeHandler = (target, index) =>
    {
        let editedObject = currentBatch.userGroups[index];
        editedObject[target.name] = target.value;

        setCurrentBatch({...currentBatch,
            userGroups:[
                ...currentBatch.userGroups.slice(0, index),
                editedObject,
                ...currentBatch.userGroups.slice(index + 1),
            ]});


    }


    const removeGroupHandler = (index) =>{
        setCurrentBatch({...currentBatch,
            userGroups:[
                ...currentBatch.userGroups.slice(0, index),
                ...currentBatch.userGroups.slice(index + 1),
            ]});
    }



    useEffect(()=>{
        window.M.AutoInit();
        window.M.updateTextFields();
    },[])

    //
    // useEffect(()=>{
    //     userGroupUpdated(userGroups);
    // },[userGroups])

    // const configOverwritesUpdatedHandler =(index,newConfigs)=>{
    //     let editedObject = userGroups[index];
    //     editedObject.configValueOverwrites = newConfigs;
    //
    //     setUserGroups(userGroups => {
    //         return [
    //             ...userGroups.slice(0, index),
    //             editedObject,
    //             ...userGroups.slice(index + 1),
    //         ]
    //     })
    // }

    return(
        <>
            <div className="row">
                <div className="col s6">
                    <button className="btn green lighten-1 black-text"
                            onClick={createHandler}
                    >Add Group</button>
                </div>
            </div>
            <div className="row">
                <ul className="collapsible">
                    {currentBatch.userGroups.map(function (userGroup, i) {
                        return (
                            <li>
                                <div className="collapsible-header">{userGroup.name} - {userGroup.percentage}</div>
                                <div className="collapsible-body no-padding">
                                    <div className="input-field" style={{marginTop:'2rem'}}>
                                        <input placeholder="Enter new batch name"
                                               id="name"
                                               type="text"
                                               name="name"
                                               value={userGroup.name}
                                               onChange={(e)=>userGroupChangeHandler(e.target,i)}/>
                                        <label htmlFor="name">Name</label>
                                    </div>
                                    <div className="input-field" style={{marginTop:'2rem'}}>
                                        <input placeholder="Enter new batch percentage"
                                               id="name"
                                               type="number"
                                               name="percentage"
                                               value={userGroup.percentage}
                                               onChange={(e)=>userGroupChangeHandler(e.target,i)}/>
                                        <label htmlFor="name">Percentage</label>
                                    </div>

                                    <UserGroupConfigOverwrites gameId={gameId} groupIndex={i} currentBatch={currentBatch} setCurrentBatch={setCurrentBatch} remoteConfigsList={remoteConfigsList}/>

                                    <button className="btn waves-effect waves-light orange"
                                            onClick={() => removeGroupHandler(i)}
                                    >
                                        <i className="material-icons">delete</i>
                                    </button>

                                </div>
                            </li>
                        );
                    })}

                </ul>
            </div>



        </>
    )
}
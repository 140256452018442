import React, {useContext, useEffect, useState} from 'react';
import 'materialize-css';
import {useHttp} from "../hooks/http.hook";
import {useMessage} from "../hooks/message.hook";
import {AuthContext} from "../context/auth.context";


export const AuthPage = () =>{
    const auth = useContext(AuthContext);
    const message = useMessage();
    const {loading,error, request,clearError} = useHttp();
    const [tab,setTab] = useState('login');


    useEffect(()=>{
        message(error);
        clearError();
    },[error,message,clearError])

    useEffect(()=>{

        window.M.AutoInit();
        window.M.updateTextFields();
    },[tab])

    const [form, setform] = useState({
        email:"",
        password:"",
    })

    const [registerForm, setRegisterForm] = useState({
        email:"",
        password:"",
        name:"",
        repeatPassword:"",
        // newUserType:""
    })

    const [forgotForm, setForgotForm] = useState({
        email:"",
    })

    const changeHandler = event =>
    {
        setform({...form, [event.target.name]:event.target.value})
    }

    const registerChangeHandler = event =>
    {
        setRegisterForm({...registerForm, [event.target.name]:event.target.value})
    }

    const forgotFormChangeHandler = event =>
    {
        setForgotForm({...forgotForm, [event.target.name]:event.target.value})
    }


    const loginHandler =async () =>{
        try {
            const data = await  request('/api/auth/login', 'POST', {...form});
            auth.login(data.accessToken,data.userId,data.userType,data.name,data.email);
        }catch (e) {
        }
    }

    const sendPassHashHandler =async () =>{
        try {
            const data = await  request('/api/auth/sendPasswordHash', 'POST', {...forgotForm});
            message(data.message);

            setTab("login");
        }catch (e) {
        }
    }

    const registerPageHandler =async () =>{
        setTab("register");
    }

    const forgotPasswordPageHandler = async (e)=>{
        e.preventDefault();

        setTab("forgotPassword");
    }

    const backHandler =async () =>{
        setTab("login");
    }

    const HandleSubmit = async (e) =>
    {
        e.preventDefault();
    }

    const registerHandler =async () =>{
        try {
            let form = {...registerForm};
            if(form.name === "")
            {
                message("Name is Empty");
                return null;
            }

            if(form.email === "")
            {
                message("Email is Empty");
                return null;
            }

            if(form.password === "")
            {
                message("Password is Empty");
                return null;
            }

            if(form.password.length < 6)
            {
                message("Password minimum 6 symbols");
                return null;
            }


            if(form.repeatPassword === "")
            {
                message("Repeat password is Empty");
                return null;
            }


            if(form.password !== form.repeatPassword)
            {
                message("Passwords don't match");
                return null;
            }


            const data = await  request('/api/auth/register', 'POST', form);
            message(data.message);

            setTab("login");

        }catch (e) {
        }
    }


    const printLoginPage = ()=>
    {
        return (
            <div className="row " style={{display:(tab==="login"?"block":"none")}}>
                <div className="col m6 offset-m3 s12 ">
                    <form className="card grey darken-3" onSubmit={HandleSubmit}>
                        <div className="card-content white-text login-container">
                            <img className="authLogo" src={require('../img/logo_white.png')}/>
                            <div>
                                <div className="input-field">
                                    <input placeholder="Enter your email"
                                           id="email"
                                           type="email"
                                           name="email"
                                           className="yellow-input"
                                           value={form.email}
                                           onChange={changeHandler}/>
                                    <label htmlFor="email">Email</label>
                                </div>
                                <div className="input-field">
                                    <input
                                        placeholder="Enter your password"
                                        id="password"
                                        type="password"
                                        name="password"
                                        className="yellow-input"
                                        value={form.password}
                                        onChange={changeHandler}
                                        disabled={loading}/>
                                    <label htmlFor="password">Password</label>
                                </div>
                                <div>
                                    <a href="#" onClick={forgotPasswordPageHandler}>Forgot Password</a>
                                </div>
                            </div>
                        </div>
                        <div className="card-action">
                            <button className="btn green darken-1" style={{marginRight:10}}
                                    onClick={loginHandler} type="submit">Login</button>


                            <button className="btn yellow darken-4" style={{marginRight:10}}
                                    onClick={registerPageHandler} type="submit">Register</button>
                        </div>
                    </form>
                </div>

            </div>
        )
    }

    const printRegisterPage = ()=>
    {
        return (
            <div className="row" style={{display:(tab==="register"?"block":"none")}}>

                <div className="col m6 offset-m3 s12 ">
                    <form className="card grey darken-3" onSubmit={HandleSubmit}>

                        <div className="card-content white-text login-container">

                            <h4>Manager Registration</h4>
                            <div>
                                <div className="input-field">
                                    <input placeholder="Name"
                                           id="email"
                                           type="text"
                                           name="name"
                                           className="yellow-input"
                                           value={registerForm.name}
                                           onChange={registerChangeHandler}/>
                                    {/*<label htmlFor="email">Name</label>*/}
                                </div>
                                <div className="input-field">
                                    <input placeholder="Email"
                                           id="email"
                                           type="email"
                                           name="email"
                                           className="yellow-input"
                                           value={registerForm.email}
                                           onChange={registerChangeHandler}/>
                                    {/*<label htmlFor="email">Email</label>*/}
                                </div>
                                <div className="input-field">
                                    <input
                                        placeholder="Password"
                                        id="password"
                                        type="password"
                                        name="password"
                                        className="yellow-input"
                                        value={registerForm.password}
                                        onChange={registerChangeHandler}
                                        disabled={loading}/>
                                    {/*<label htmlFor="password">Password</label>*/}
                                </div>
                                <div className="input-field">
                                    <input
                                        placeholder="Repeat your password"
                                        id="password"
                                        type="password"
                                        name="repeatPassword"
                                        className="yellow-input"
                                        value={registerForm.repeatPassword}
                                        onChange={registerChangeHandler}
                                        disabled={loading}/>
                                    {/*<label htmlFor="password">Repeat Password</label>*/}
                                </div>
                            </div>
                        </div>
                        <div className="card-action">
                            <button className="btn green darken-1" style={{marginRight:10}}
                                    onClick={registerHandler}>Register Manager</button>

                            <button className="btn yellow darken-4" style={{marginRight:10}}
                                    onClick={backHandler}>Back</button>
                        </div>
                    </form>
                </div>

            </div>
        )
    }

    const printForgotPasswordPage = ()=>
    {
        return (
            <div className="row ">
                <div className="col m6 offset-m3 s12 ">
                    <form className="card grey darken-3" onSubmit={HandleSubmit}>
                        <div className="card-content white-text login-container">
                            <img className="authLogo" src={require('../img/logo_white.png')}/>
                            <h5>Forgot Password</h5>
                            <br />
                            <div>
                                <div className="input-field">
                                    <input placeholder="Enter your email"
                                           id="email"
                                           type="email"
                                           name="email"
                                           className="yellow-input"
                                           value={forgotForm.email}
                                           onChange={forgotFormChangeHandler}/>
                                    <label htmlFor="email">Email</label>
                                </div>

                            </div>
                        </div>
                        <div className="card-action">
                            <button className="btn green darken-1" style={{marginRight:10}}
                                    onClick={sendPassHashHandler} type="submit">Send Email</button>


                            <button className="btn yellow darken-4" style={{marginRight:10}}
                                    onClick={backHandler}>Back</button>
                        </div>
                    </form>
                </div>

            </div>
        )
    }

    const OpenPage = () =>{

        if(tab === "login" || tab === "register")
        {
            return (
                <>
                    {printLoginPage()}
                    {printRegisterPage()}
                </>
            )
        }
        else if(tab === "forgotPassword")
        {
            return printForgotPasswordPage();
        }

    }

    return(
        <>
            {OpenPage()}
        </>

    )
}
import React, {useState} from 'react';
import {useCallback, useContext, useEffect} from "react";
import {useStats} from "../../hooks/stats.hook";

export const GlobalFilters = ({searchQuery, filtersUpdatedCallback}) =>
{
    const {getFilters} = useStats();

    const [filterOptions, setFilterOptions] = useState({
        event_filters:{
            adFormats:[],
            customEventNames:[],
            eventNames:[],
            remote_configs:[]
        },
        user_filters:{
            countries:[],
            platforms:[],
            versions:[]
        }
    });

    let emptyFilters = {
        event_filters:{
            adFormats:[],
            customEventNames:[],
            eventNames:[],
            remote_configs:{}
        },
        user_filters:{
            countries:[],
            platforms:[],
            versions:[]
        }
    }
    const [appliedFilters, setAppliedFilters] = useState(emptyFilters);


    const fetchFilters = useCallback(async ()=>{
        const filters = await  getFilters(searchQuery);

        if(filters.event_filters)
        {
            setFilterOptions(filters);
            setAppliedFilters(emptyFilters);
        }

    },[searchQuery]);

    useEffect(()=>{
        fetchFilters();
    },[searchQuery])


    useEffect(()=>{
        filtersUpdatedCallback(appliedFilters);
    },[appliedFilters])

    const toggleCountrySelectbox = (val)=>{

        let currentArray = appliedFilters.user_filters.countries;
        let previouslySelected = currentArray.indexOf(val) !== -1;
        if(previouslySelected)
        {
            currentArray.splice(currentArray.indexOf(val), 1);
        }
        else
        {
            currentArray.push(val);
        }

        let newFilters = appliedFilters;
        newFilters.user_filters.countries = currentArray;
        setAppliedFilters(appliedFilters=>({...appliedFilters,...newFilters}));
    }

    const togglePlatformsSelectbox = (val)=>{
        let currentArray = appliedFilters.user_filters.platforms;
        let previouslySelected = currentArray.indexOf(val) !== -1;
        if(previouslySelected)
        {
            currentArray.splice(currentArray.indexOf(val), 1);
        }
        else
        {
            currentArray.push(val);
        }

        let newFilters = appliedFilters;
        newFilters.user_filters.platforms = currentArray;
        setAppliedFilters(appliedFilters=>({...appliedFilters,...newFilters}));
    }

    const toggleVersionsSelectbox = (val)=>{
        let currentArray = appliedFilters.user_filters.versions;
        let previouslySelected = currentArray.indexOf(val) !== -1;
        if(previouslySelected)
        {
            currentArray.splice(currentArray.indexOf(val), 1);
        }
        else
        {
            currentArray.push(val);
        }

        let newFilters = appliedFilters;
        newFilters.user_filters.versions = currentArray;
        setAppliedFilters(appliedFilters=>({...appliedFilters,...newFilters}));

    }

    const remoteConfigFilterChanged = (e,remoteConfigName)=>{
        let newFilters = appliedFilters;
        newFilters.event_filters.remote_configs[remoteConfigName] = e.target.value;
        setAppliedFilters(appliedFilters=>({...appliedFilters,...newFilters}));
    }

    return (
        <>
            <div className="row">
                <div className="col s12 m12">
                    <ul className="collapsible">
                        <li>
                            <div className="collapsible-header">Filters</div>
                            <div className="collapsible-body">
                                <div className="row">
                                    <div className="col s12 m12">
                                        <ul className="collapsible">
                                            <li>
                                                <div className="collapsible-header">Platform</div>
                                                <div className="collapsible-body">
                                                    {filterOptions.user_filters.platforms && filterOptions.user_filters.platforms.map((platform,index) =>{
                                                        return (
                                                            <>
                                                                <label>
                                                                    <input type="checkbox" checked={(appliedFilters.user_filters.platforms.indexOf(platform) !== -1) ? "checked" : ""}
                                                                           onChange={e => {
                                                                               togglePlatformsSelectbox(platform);
                                                                           }} className="filled-in"/>
                                                                    <span>{platform}</span>
                                                                </label><br/>
                                                            </>

                                                        )
                                                    })}
                                                </div>
                                            </li>
                                            <li>
                                                <div className="collapsible-header">Build</div>
                                                <div className="collapsible-body">
                                                    {filterOptions.user_filters.versions && filterOptions.user_filters.versions.map((version,index) =>{
                                                        return (
                                                            <>
                                                                <label>
                                                                    <input type="checkbox" checked={(appliedFilters.user_filters.versions.indexOf(version) !== -1) ? "checked" : ""}
                                                                           onChange={e => {
                                                                               toggleVersionsSelectbox(version);
                                                                           }} className="filled-in"/>
                                                                    <span>{version}</span>
                                                                </label><br/>
                                                            </>

                                                        )
                                                    })}
                                                </div>
                                            </li>
                                            <li>
                                                <div className="collapsible-header">Country</div>
                                                <div className="collapsible-body">
                                                    {filterOptions.user_filters.countries && filterOptions.user_filters.countries.map((country,index) =>{
                                                        return (
                                                            <> <label>
                                                                <input type="checkbox" checked={(appliedFilters.user_filters.countries.indexOf(country) !== -1) ? "checked" : ""}
                                                                       onChange={e => {
                                                                           toggleCountrySelectbox(country);
                                                                       }} className="filled-in"/>
                                                                <span>{country}</span>
                                                            </label><br/>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </li>
                                            <li>
                                                <div className="collapsible-header">Remote Configs</div>
                                                <div className="collapsible-body">
                                                    <div className="row"> {filterOptions.event_filters.remote_configs && filterOptions.event_filters.remote_configs.map((remote_config,index) =>{
                                                        return (
                                                            <>
                                                                <div className="input-field col s6">
                                                                    <span className="helper-text" data-error="wrong"
                                                                          data-success="right"> {remote_config}</span>
                                                                    <input placeholder="Placeholder"
                                                                           type="text" className="validate" value=
                                                                               {appliedFilters.event_filters.remote_configs[remote_config]}
                                                                               onChange={e => {
                                                                                   remoteConfigFilterChanged(e,remote_config);
                                                                               }}
                                                                    />

                                                                </div>
                                                            </>
                                                        )
                                                    })}</div>

                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>

        </>
    )
}
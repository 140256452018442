import React, {useState} from 'react';
import {useCallback, useContext, useEffect} from "react";
import {useStats} from "../../hooks/stats.hook";
import {NewUsersChart} from "./Widgets/NewUsersChart";

export const CohortsOverview = ({searchQuery,globalFilters}) =>
{
    const {getCohortsOverview} = useStats();
    const [cohortOverviewStats, setCohortOverviewStats] = useState({});
    const [globalData, setGlobalData] = useState({
        dayAxis: {
            labels:[],
            dates:[]
        }
    });


    const DoQuery = useCallback(async ()=>{

        if(!searchQuery.gameId || !searchQuery.dateFrom || !searchQuery.dateTo) return;

        const stats = await  getCohortsOverview(searchQuery, globalFilters);
        console.log(stats);
        setCohortOverviewStats(stats);

    },[searchQuery,globalFilters]);

    useEffect(()=>{
        // DoQuery();
    },[,searchQuery,globalFilters]);


    useEffect(()=>{
        DoProcessing();
    },[cohortOverviewStats]);


    const DoProcessing =()=>
    {
        UpdateDayAxis();
    }


    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const UpdateDayAxis = ()=>{

        const startDay = new Date(searchQuery.dateFrom);
        const endDay = new Date(searchQuery.dateTo);

        let dayAxis = {
            labels:[],
            dates:[]
        };

        for (let d = startDay; d <= endDay; d.setDate(d.getDate() + 1)) {
            let newDate = new Date(d);
            dayAxis.dates.push(newDate);
            dayAxis.labels.push(newDate.getDate() + " "+ months[newDate.getMonth()]);
        }

        setGlobalData({...globalData,dayAxis: dayAxis});

    }


    const LoadDataHandler =async ()=>{
        DoQuery();
    }


    return(
        <>
            <div className="row">
                <div className="col m6 s12">
                    <button className="btn waves-effect waves-light" onClick={LoadDataHandler}>Load
                    </button>
                    <NewUsersChart globalData={globalData} statsData={cohortOverviewStats}/>
                </div>
            </div>
        </>
    )
}
import React, {useState} from 'react';
import {useCallback, useContext, useEffect} from "react";
import {useHttp} from "../hooks/http.hook";
import {GamesList} from "../components/gamesList";

export const GamesPage = () =>{
    const {loading, request} = useHttp();

    const [gamesList, setGamesList] = useState([]);


    const fetchGames = useCallback(async ()=>{
        try{
            const fetchedGames = await request("/api/game/allGames","POST",null,true);
            setGamesList(fetchedGames);

        }catch (e) {

        }
    },[request]);

    const callbackFunction = (childData) => {
        fetchGames();
    }

    useEffect(()=>{
        fetchGames();
    },[]);



    return(
        <div>
            <h5>Games</h5>
            {!loading && <GamesList games={gamesList}/>}
        </div>
    )
}
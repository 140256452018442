import React, {useContext} from 'react';
import {NavLink, useNavigate,useLocation} from 'react-router-dom';
import {AuthContext} from "../context/auth.context";

export const Navbar= () =>{
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useContext(AuthContext);
    const logoutHandler = event =>{
        event.preventDefault();
        auth.logout();
        navigate("/");
    }

    const PrintNavLinks = () =>
    {

        return (
            <>
                <li className={location.pathname === "/analytics"?"active":""}><NavLink to="/analytics">Analytics</NavLink></li>
                <li className={location.pathname === "/games"?"active":""}><NavLink to="/games">Games</NavLink></li>
                <li className={location.pathname === "/create"?"active":""}><NavLink to="/create">Create Game</NavLink></li>


                <li><a href="/" onClick={logoutHandler}>Logout</a></li>
            </>
        )
    }

    return (
        <>
            <nav>
                <div className="nav-wrapper grey darken-3" style={{padding:"0 2rem"}}>
                    <a href="#!" className="brand-logo">
                        <img src={require('../img/logo_white.png')}/>
                        <h6 className="header_email">{auth.userEmail}</h6>
                    </a>
                    <a href="#" data-target="mobile-demo" className="sidenav-trigger"><i
                        className="material-icons">menu</i></a>
                    <ul className="right hide-on-med-and-down">
                        {PrintNavLinks()}
                    </ul>
                </div>
            </nav>

            <ul className="sidenav" id="mobile-demo">
                {PrintNavLinks()}
            </ul>

        </>

    )
}
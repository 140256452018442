import {useState, useCallback, useContext,useEffect} from 'react';
import {useHttp} from "./http.hook";


export const useBatch = () => {
    const {request} = useHttp();

    const createBatch = useCallback(async (gameId, form)=>{
        try{
            form.gameId = gameId;

            const data = await request("/api/batch/createBatch","POST", {form},true);

            return data;
        }catch (e) {

        }
    },[request]);



    const updateBatch = useCallback(async (form)=>{
        try{

            const data = await request("/api/batch/updateBatch","POST", {form},true);

            return data;
        }catch (e) {

        }
    },[request]);


    const getAllBatches = useCallback(async (gameId)=>{
        try{
            const data = await request("/api/batch/getAllBatches","POST", {gameId},true);

            return data;
        }catch (e) {

        }
    },[request]);

    const getBatch = useCallback(async (batchId)=>{
        try{
            // const data = await request("/api/remoteConfig/getAllConfigs","POST", {gameId},true);
            //
            // return data;
        }catch (e) {

        }
    },[request]);



    return  {createBatch,getAllBatches, updateBatch , getBatch}
}
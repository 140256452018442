import React, {useState} from 'react';
import {useCallback, useContext, useEffect} from "react";
import {useRemoteConfig} from "../../hooks/remoteConfig.hook";



export const RemoteConfigsList = ({gameId}) =>
{
    const [remoteConfigsList, setRemoteConfigsList] = useState([]);

    const {getAllRemoteConfigs} = useRemoteConfig();


    const fetchRemoteConfigs = async  () =>{
        let data = await getAllRemoteConfigs(gameId);

        setRemoteConfigsList(data);
    }

    useEffect(()=>{
        fetchRemoteConfigs();
    },[]);

    const drawConfigsTable = (data) =>{

        if(!data || data.length == 0) return (<>No Data</>);

        return (
            <>
                <div className="section">
                    <table>
                        <thead>
                        <tr>
                            <th>Key</th>
                            <th>Type</th>
                            <th>Default Value</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((remoteConfig,index) =>{

                            return (
                                <tr key={index}>
                                    <td>{remoteConfig.configKey}</td>
                                    <td>{remoteConfig.configType}</td>
                                    <td>{remoteConfig.defaultValue}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
                <div className="divider"></div>
            </>
        )
    }


    return(
        <>
            {drawConfigsTable(remoteConfigsList)}
        </>
    )
}
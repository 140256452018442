import React, {useState} from 'react';
import {useCallback, useContext, useEffect} from "react";
import {useGame} from "../hooks/game.hook";
import {StatsOverview} from "../components/Stats/StatsOverview";
import {CohortsOverview} from "../components/Stats/CohortsOverview";
import {UserActivity} from "../components/Stats/UserActivity";
import {GlobalFilters} from "../components/Stats/GlobalFilters";
import {Monetization} from "../components/Stats/Monetization";
import {useHttp} from "../hooks/http.hook";
import {useStats} from "../hooks/stats.hook";


export const AnalyticsPage = () =>{
    const {loading} = useHttp();



    const [gamesList, setGamesList] = useState([]);

    const [searchQuery, setSearchQuery] = useState({
        gameId:"",
        dateFrom:"",
        dateTo:"",
    });

    const [globalFilters, setGlobalFilters] = useState({});

    const [dateFrom, setDateForm] = useState();
    const [dateTo, setDateTo] = useState();

    const {getAllGames} = useGame();
    const fetchGames = useCallback(async ()=>{
        const _gamesList = await  getAllGames();
        // console.log(_gamesList);
        setGamesList(_gamesList);

    },[]);



    const gameSelectChangeHandler = async(e)=>{
        const value = e.target.value;
        setSearchQuery({...searchQuery,gameId:value});
    }

    const filtersUpdated =(newFilters) =>{
        setGlobalFilters(globalFilters=>({...globalFilters,...newFilters}));
    }


    const InitDatePickers = ()=>{
        let now = new Date();
        let yesterday = new Date();

        yesterday.setDate(yesterday.getDate() - 5);

        let elem = document.getElementById("dateFromPicker");
        let dateFromInstance = window.M.Datepicker.init(elem,{
            onClose:()=>{
                setDateForm(dateFromInstance.toString());
            }
        });
        dateFromInstance.setDate(yesterday);
        setDateForm(dateFromInstance.toString());


        elem = document.getElementById("dateToPicker");
        let dateToInstance = window.M.Datepicker.init(elem,{
            onClose:()=>{
                setDateTo(dateToInstance.toString());
            }
        });
        dateToInstance.setDate(now);
        setDateTo(dateToInstance.toString());


        setSearchQuery({...searchQuery,dateFrom:dateFromInstance.toString(),dateTo:dateToInstance.toString()});

    }


    useEffect(()=>{
        window.M.AutoInit();
        window.M.updateTextFields();

        InitDatePickers();

        fetchGames();
    },[]);

    useEffect(()=>{
        window.M.AutoInit();
        InitDatePickers();
    },[gamesList]);


    useEffect(()=>{
        console.log(searchQuery);
        if(dateFrom && dateTo)
          setSearchQuery({...searchQuery,dateFrom: dateFrom, dateTo: dateTo})
    },[dateTo,dateFrom]);


    return(
        <div>

            <div className="row">
                <div className="col s12 m12">
                    <div className="card">
                        <div className="card-content">
                            <div className="input-field col s12">
                                <select value={searchQuery.gameId} onChange={gameSelectChangeHandler}>
                                    <option value="" disabled>Select Game</option>
                                    {gamesList.map(function(gamesList, i){
                                        return (
                                            <option key={i} value={gamesList._id}>{gamesList.name}</option>
                                        );
                                    })}
                                </select>
                                <label>Game</label>
                            </div>
                            <div className="row">
                                <div className="col s6">
                                    <h6>From</h6>
                                    <input type="text" className="datepicker" readOnly={true}  value={searchQuery.dateFrom}  id="dateFromPicker"/>
                                </div>
                                <div className="col s6">
                                    <h6>To</h6>
                                    <input type="text" className="datepicker" readOnly={true}  value={searchQuery.dateTo} id="dateToPicker" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <GlobalFilters searchQuery={searchQuery} filtersUpdatedCallback={filtersUpdated} />

            <div className="row">
                <div className="col s12 m12">
                    <div className="card">
                        <div className="card-content">
                            <div className="row">
                                <div className="col s12">
                                    <ul className="tabs">
                                        <li className="tab col s3"><a  href="#stats_tab">Stats</a></li>
                                        <li className="tab col s3"><a  href="#cohorts_tab">Cohorts</a></li>
                                        <li className="tab col s3"><a className="active" href="#monetization_tab">Monetization</a></li>
                                        <li className="tab col s3"><a href="#user_activity">User Activity</a></li>
                                    </ul>
                                </div>
                                <div id="stats_tab" className="col s12">
                                    <StatsOverview searchQuery={searchQuery} globalFilters={globalFilters}/>
                                </div>
                                <div id="cohorts_tab" className="col s12">
                                    <CohortsOverview searchQuery={searchQuery} globalFilters={globalFilters}/>
                                </div>
                                <div id="monetization_tab" className="col s12">
                                    <Monetization  searchQuery={searchQuery} globalFilters={globalFilters}/>
                                </div>
                                <div id="user_activity" className="col s12">
                                    <UserActivity  searchQuery={searchQuery} globalFilters={globalFilters}/>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    )
}
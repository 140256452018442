import {useState, useCallback, useContext,useEffect} from 'react';
import {useHttp} from "./http.hook";


export const useStats = () => {
    const {request} = useHttp();

    const getFilters = useCallback(async (query)=>{
        try{
            const data = await request("/api/stats/get_filters","POST",query,true);

            return data;
        }catch (e) {

        }
    },[request]);

    const getOverview = useCallback(async (query, globalFilters)=>{
        try{
            query.globalFilters = globalFilters;

            const data = await request("/api/stats/overview","POST",query,true);

            return data;
        }catch (e) {

        }
    },[request]);

    const getMonetizationOverview = useCallback(async (query, globalFilters)=>{
        try{
            query.globalFilters = globalFilters;

            const data = await request("/api/stats/monetization_overview","POST",query,true);

            return data;
        }catch (e) {

        }
    },[request]);

    const getCohortsOverview = useCallback(async (query,globalFilters)=>{
        try{
            query.globalFilters = globalFilters;
            const data = await request("/api/stats/cohortOverview","POST",query,true);

            return data;
        }catch (e) {

        }
    },[request]);


    const LoadUserContent = useCallback(async (query,form)=>{
        try{
            const data = await request("/api/stats/getUserContent","POST", {query,form},true);

            return data;
        }catch (e) {
            return e;
        }
    },[request]);

    const DeleteUserContent = useCallback(async (form)=>{
        try{
            const data = await request("/api/stats/deleteContent","POST",form,true);

            return data;
        }catch (e) {
            return  e;
        }
    },[request]);

    return  {getFilters,getOverview,getCohortsOverview,DeleteUserContent,LoadUserContent,getMonetizationOverview}
}
import {useState, useCallback, useContext,useEffect} from 'react';
import {useHttp} from "./http.hook";


export const useGame = () => {
    const {request} = useHttp();


    const getAllGames = useCallback(async ()=>{
        try{
            const data = await request("/api/game/allGames","POST",null,true);

            return data;
        }catch (e) {

        }
    },[request]);

    return  {getAllGames}
}
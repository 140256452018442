import React, {useState} from 'react';
import {useCallback, useContext, useEffect} from "react";
import {useBatch} from "../../hooks/batch.hook";
import {UserGroupsView} from "./UserGroupsView";



export const BatchEditView = ({currentBatch,setCurrentBatch, gameId, remoteConfigsList}) =>
{
    const {createBatch,updateBatch} = useBatch();

    const allCountries = [
        {
            id: 840,
            country: "United States",
            code: "US",
            alpha3: "USA"
        },
        {
            id: 51,
            country: "Armenia",
            code: "AM",
            alpha3: "ARM"
        },
        {
            id: 40,
            country: "Austria",
            code: "AT",
            alpha3: "AUT"
        },
        {
            id: 56,
            country: "Belgium",
            code: "BE",
            alpha3: "BEL"
        },
        {
            id: 100,
            country: "Bulgaria",
            code: "BG",
            alpha3: "BGR"
        },
        {
            id: 191,
            country: "Croatia",
            code: "HR",
            alpha3: "HRV"
        },
        {
            id: 196,
            country: "Cyprus",
            code: "CY",
            alpha3: "CYP"
        },
        {
            id: 203,
            country: "Czech Republic",
            code: "CZ",
            alpha3: "CZE"
        },
        {
            id: 208,
            country: "Denmark",
            code: "DK",
            alpha3: "DNK"
        },
        {
            id: 233,
            country: "Estonia",
            code: "EE",
            alpha3: "EST"
        },
        {
            id: 246,
            country: "Finland",
            code: "FI",
            alpha3: "FIN"
        },
        {
            id: 250,
            country: "France",
            code: "FR",
            alpha3: "FRA"
        },
        {
            id: 276,
            country: "Germany",
            code: "DE",
            alpha3: "DEU"
        },
        {
            id: 300,
            country: "Greece",
            code: "GR",
            alpha3: "GRC"
        },
        {
            id: 348,
            country: "Hungary",
            code: "HU",
            alpha3: "HUN"
        },
        {
            id: 372,
            country: "Ireland, Republic of (EIRE)",
            code: "IE",
            alpha3: "IRL"
        },
        {
            id: 380,
            country: "Italy",
            code: "IT",
            alpha3: "ITA"
        },
        {
            id: 428,
            country: "Latvia",
            code: "LV",
            alpha3: "LVA"
        },
        {
            id: 440,
            country: "Lithuania",
            code: "LT",
            alpha3: "LTU"
        },
        {
            id: 442,
            country: "Luxembourg",
            code: "LU",
            alpha3: "LUX"
        },
        {
            id: 470,
            country: "Malta",
            code: "MT",
            alpha3: "MLT"
        },
        {
            id: 528,
            country: "Netherlands",
            code: "NL",
            alpha3: "NLD"
        },
        {
            id: 616,
            country: "Poland",
            code: "PL",
            alpha3: "POL"
        },
        {
            id: 620,
            country: "Portugal",
            code: "PT",
            alpha3: "PRT"
        },
        {
            id: 642,
            country: "Romania",
            code: "RO",
            alpha3: "ROU"
        },
        {
            id: 703,
            country: "Slovakia",
            code: "SK",
            alpha3: "SVK"
        },
        {
            id: 705,
            country: "Slovenia",
            code: "SI",
            alpha3: "SVN"
        },
        {
            id: 724,
            country: "Spain",
            code: "ES",
            alpha3: "ESP"
        },
        {
            id: 752,
            country: "Sweden",
            code: "SE",
            alpha3: "SWE"
        },
        {
            id: 826,
            country: "United Kingdom",
            code: "GB",
            alpha3: "GBR"
        }
    ];


    const isCountrySelected =(contryRow)=>
    {
        return currentBatch.countries.indexOf(contryRow.code) !== -1;
    }

    const updateSelectedCountry =(contryRow, element)=>
    {
        let searchedIndex = currentBatch.countries.indexOf(contryRow.code);
        if (searchedIndex !== -1)
        {
            currentBatch.countries.splice(searchedIndex, 1);

            setCurrentBatch({...currentBatch,countries:currentBatch.countries});
        }
        else
        {
            currentBatch.countries.push(contryRow.code);
            setCurrentBatch({...currentBatch,countries:currentBatch.countries});
        }
    }


    const changeHandler = event =>
    {
        setCurrentBatch({...currentBatch, [event.target.name]:event.target.value})
    }

    const userGroupUpdateHandler = (newUserGroups)=>{
        setCurrentBatch({...currentBatch, userGroups:newUserGroups})
    }

    const saveHandler = async event =>{

        let countries_select_list_Instance = window.M.FormSelect.getInstance( window.document.getElementById('countries_select_list'));
        let countries_list = countries_select_list_Instance.getSelectedValues();

        currentBatch.countries = countries_list;

        if(currentBatch.draftBatch)
        {
            await createBatch(gameId,currentBatch);
        }
        else
        {
            await updateBatch(currentBatch);
        }

    }


    useEffect(()=>{
        window.M.AutoInit();
        window.M.updateTextFields();
    },[])

    return(
        <>
            <div  style={{display:(currentBatch.currentBatchId == null && !currentBatch.draftBatch)?"none":"block"}}>
                <div className="row">

                    <select className="browser-default col s6" name="batchStatus" style={{width:"281px"}} value={currentBatch.batchStatus} onChange={changeHandler}>
                        <option value="development">Development</option>
                        <option value="live">Live</option>
                        <option value="archived">Archived</option>
                    </select>
                </div>
                <div className="row">
                    <div className="input-field col s6" >
                        <input placeholder="Enter new batch name"
                               id="name"
                               type="text"
                               name="name"
                               value={currentBatch.name}
                               onChange={changeHandler}/>
                        <label htmlFor="name">Name</label>
                    </div>

                </div>

                <div className="row">
                    <div  className="input-field col s4">
                        <select multiple id="countries_select_list">
                            {allCountries.map(function (countryRow, i) {
                                return (
                                    <option  key={i} selected={isCountrySelected(countryRow)?"selected":""} value={countryRow.code}> {countryRow.country}</option>
                                );
                            })}
                        </select>
                        <label>Countries</label>
                    </div>
                </div>

                <UserGroupsView  gameId={gameId} currentBatch={currentBatch} setCurrentBatch={setCurrentBatch} userGroupUpdated={userGroupUpdateHandler} remoteConfigsList={remoteConfigsList}/>

                <div className="col s6">
                    <button className="btn green lighten-1 black-text"
                            onClick={saveHandler}
                    >Save</button>
                </div>
            </div>
        </>
    )
}
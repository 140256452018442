import React, {useState} from 'react';
import {useCallback, useContext, useEffect} from "react";
import {useStats} from "../../hooks/stats.hook";
import {useMessage} from "../../hooks/message.hook";

export const UserActivity = ({searchQuery,globalFilters}) =>
{
    const {DeleteUserContent,LoadUserContent} = useStats();
    const message = useMessage();

    const [form, setform] = useState({
        deviceId:"",
        userId:"",
    })

    const [userActivity, setUserActivity] = useState({
        user:{},
        events:[],
    })


    const LoadDataHandler =async ()=>{
        try {
            const data = await LoadUserContent(searchQuery,form);
            if(data.message) return  message(data.message);

            setUserActivity(data);

        }catch (e) {
            if(e && e.message) {
                message(e.message);
            }
            else if(e)
            {
                message(e);
            }
        }
    }

    const DeleteContentHandler =async()=>{
        try {
            const data = await  DeleteUserContent(form);
            message(data.message);
        }catch (e) {
            if(e && e.message) {
                message(e.message);
            }
            else if(e)
            {
                message(e);
            }
        }
    }

    return(
        <>
            <div className="row">
                <div className="input-field col s12 m5">
                    <input placeholder="Device id" id="device_id" type="text"
                           name="deviceId"
                           value={form.deviceId}
                           onChange={(event)=>{setform({...form, [event.target.name]:event.target.value})}}/>
                    <label htmlFor="device_id">Device Id</label>
                </div>
                <div className="input-field col s12 m1 center-align">
                    <h6>OR</h6>
                </div>
                <div className="input-field col s12 m5">
                    <input placeholder="User id" id="user_id" type="text"
                           name="userId"
                           value={form.userId}
                           onChange={(event)=>{setform({...form, [event.target.name]:event.target.value})}}/>
                    <label htmlFor="user_id">User Id</label>
                </div>

            </div>

            <div className="row">
                <div className="col s12 m12">
                    <button className="btn waves-effect waves-light" onClick={LoadDataHandler}>Load
                    </button>
                    <button className="btn waves-effect waves-light  red darken-2" style={{marginLeft:"10px"}} onClick={DeleteContentHandler} >Delete Content
                    </button>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12">
                    <pre>{JSON.stringify(userActivity.user,null,2)}</pre>
                </div>
            </div>

            <div className="row">
                <div className="col s12 m12">
                    <ul className="collapsible">
                        {userActivity.events.map(function(event, i){
                            return (
                                <>
                                    <li>
                                        <div className="collapsible-header">{event.event_name}</div>
                                        <div className="collapsible-body">
                                            <pre>{JSON.stringify(event,null,2)}</pre>
                                        </div>
                                    </li>
                                </>
                            );
                        })}
                        
                    </ul>
                </div>
            </div>

        </>
    )
}
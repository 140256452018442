import React from 'react';
import {
    Route,
    Routes,
    Navigate

} from "react-router-dom";
import  {AuthPage} from './pages/AuthPage';
import  {CreatePage} from './pages/CreatePage';
import  {DetailsPage} from './pages/DetailsPage';
import  {GamesPage} from './pages/GamesPage';
import  {AnalyticsPage} from './pages/AnalyticsPage';
import  {ABTestsPage} from './pages/ABTestsPage';
import {EmailVerifyPage} from "./pages/EmailVerifyPage";
import  {PasswordRecoveryPage} from "./pages/PasswordRecoveryPage";

export const useRoutes = (isAuthenticated, userType) =>{

    const globalRoutes = () =>{
        return(
            <>
                <Route path="/emailVerify/:hash" element={<EmailVerifyPage />} />
                <Route path="/passwordRecovery/:hash" element={<PasswordRecoveryPage />} />
            </>
        )
    }

    const marketingRoutes = () =>{
        return(
            <Routes>
                {globalRoutes()}
                <Route path="/details/:id" element={<DetailsPage />} />
                <Route path="/games/" element={<GamesPage />} />
                <Route path="/analytics/" element={<AnalyticsPage />} />
                <Route path="/abtests/:id" element={<ABTestsPage />} />
                <Route path="/create/" element={<CreatePage />} />
                <Route
                    path="*"
                    element={<Navigate to="/games/" replace />}
                />
            </Routes>
        )
    }


    if(isAuthenticated)
    {
        return marketingRoutes()
    }

    return (
        <Routes>
            {globalRoutes()}
            <Route path="/" exact element={<AuthPage />} />
            <Route
                path="*"
                element={<Navigate to="/" replace />}
            />
        </Routes>
    )
}
import React, {useContext, useEffect, useState} from 'react';
import 'materialize-css';
import {RemoteConfigsList} from "./RemoteConfigsList";
import {CreateNewConfig} from "./CreateNewConfig";


export const RemoteConfigsView = ({gameId}) =>
{

    useEffect(()=>{
        window.M.AutoInit();
        window.M.updateTextFields();
    },[])



    return(
        <>
            <div className="row">
                <div className="col s12 m12">
                    <div className="card">
                        <div className="card-content">
                            <div className="row">
                                <div className="col s12">
                                    <ul className="tabs">
                                        <li className="tab col s3"><a  href="#configs_list">All</a></li>
                                        <li className="tab col s3"><a  href="#new_config">New Config</a></li>
                                    </ul>
                                </div>
                                <div id="configs_list" className="col s12">
                                    <RemoteConfigsList gameId={gameId} />
                                </div>
                                <div id="new_config" className="col s12">
                                    <CreateNewConfig gameId={gameId} />
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
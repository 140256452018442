import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useHttp} from "../hooks/http.hook";
import {AuthContext} from "../context/auth.context";
import {Loader} from "../components/Loader";
import {useMessage} from "../hooks/message.hook";


export const DetailsPage = () =>{

    let gameId = useParams().id;
    const [game,setGame] = useState({});
    const {loading, request} = useHttp();
    const message = useMessage();

    const europeCountries = [
        {
            id: 51,
            country: "Armenia",
            code: "AM",
            alpha3: "ARM"
        },
        {
            id: 40,
            country: "Austria",
            code: "AT",
            alpha3: "AUT"
        },
        {
            id: 56,
            country: "Belgium",
            code: "BE",
            alpha3: "BEL"
        },
        {
            id: 100,
            country: "Bulgaria",
            code: "BG",
            alpha3: "BGR"
        },
        {
            id: 191,
            country: "Croatia",
            code: "HR",
            alpha3: "HRV"
        },
        {
            id: 196,
            country: "Cyprus",
            code: "CY",
            alpha3: "CYP"
        },
        {
            id: 203,
            country: "Czech Republic",
            code: "CZ",
            alpha3: "CZE"
        },
        {
            id: 208,
            country: "Denmark",
            code: "DK",
            alpha3: "DNK"
        },
        {
            id: 233,
            country: "Estonia",
            code: "EE",
            alpha3: "EST"
        },
        {
            id: 246,
            country: "Finland",
            code: "FI",
            alpha3: "FIN"
        },
        {
            id: 250,
            country: "France",
            code: "FR",
            alpha3: "FRA"
        },
        {
            id: 276,
            country: "Germany",
            code: "DE",
            alpha3: "DEU"
        },
        {
            id: 300,
            country: "Greece",
            code: "GR",
            alpha3: "GRC"
        },
        {
            id: 348,
            country: "Hungary",
            code: "HU",
            alpha3: "HUN"
        },
        {
            id: 372,
            country: "Ireland, Republic of (EIRE)",
            code: "IE",
            alpha3: "IRL"
        },
        {
            id: 380,
            country: "Italy",
            code: "IT",
            alpha3: "ITA"
        },
        {
            id: 428,
            country: "Latvia",
            code: "LV",
            alpha3: "LVA"
        },
        {
            id: 440,
            country: "Lithuania",
            code: "LT",
            alpha3: "LTU"
        },
        {
            id: 442,
            country: "Luxembourg",
            code: "LU",
            alpha3: "LUX"
        },
        {
            id: 470,
            country: "Malta",
            code: "MT",
            alpha3: "MLT"
        },
        {
            id: 528,
            country: "Netherlands",
            code: "NL",
            alpha3: "NLD"
        },
        {
            id: 616,
            country: "Poland",
            code: "PL",
            alpha3: "POL"
        },
        {
            id: 620,
            country: "Portugal",
            code: "PT",
            alpha3: "PRT"
        },
        {
            id: 642,
            country: "Romania",
            code: "RO",
            alpha3: "ROU"
        },
        {
            id: 703,
            country: "Slovakia",
            code: "SK",
            alpha3: "SVK"
        },
        {
            id: 705,
            country: "Slovenia",
            code: "SI",
            alpha3: "SVN"
        },
        {
            id: 724,
            country: "Spain",
            code: "ES",
            alpha3: "ESP"
        },
        {
            id: 752,
            country: "Sweden",
            code: "SE",
            alpha3: "SWE"
        },
        {
            id: 826,
            country: "United Kingdom",
            code: "GB",
            alpha3: "GBR"
        }
    ];

    const getGame = async()=>{
        try{
            const fetched = await request(`/api/game/game/${gameId}`,"GET",null,true)

            if(!fetched.gdprCountryCodes) fetched.gdprCountryCodes = [];

            setGame(fetched);

        }catch (e) {

        }
    }
    useEffect(()=>{getGame();},[gameId]);


    const updateDataHandler =async e =>{
        e.preventDefault();

        if(game.name === "")
        {
            message("name is Empty");
            return;
        }

        try {

            const data = await  request('/api/game/edit', 'POST', {...game},true);

            message(data.message);
        }catch (e) {

        }
    }


    const updateGDPRHandler =async e =>{
        e.preventDefault();

        try {

            const data = await  request('/api/game/updateGDPRCountries', 'POST', {...game},true);

            message(data.message);
        }catch (e) {

        }
    }


    const changeHandler = event =>
    {
        setGame({...game, [event.target.name]:event.target.value})
    }


    useEffect(()=>{
        window.M.AutoInit();
        window.M.updateTextFields();
    },[,game,message,loading])


    const isGDPRActivated =(contryRow)=>
    {  console.log(game);
        return game.gdprCountryCodes.indexOf(contryRow.code) !== -1;
    }
    const updateGDPRStatus =(contryRow, element)=>
    {
        console.log(game);
        let searchedIndex = game.gdprCountryCodes.indexOf(contryRow.code);
        if (searchedIndex !== -1)
        {
            game.gdprCountryCodes.splice(searchedIndex, 1);

            setGame({...game,gdprCountryCodes:game.gdprCountryCodes});
        }
        else
        {
            game.gdprCountryCodes.push(contryRow.code);
            setGame({...game,gdprCountryCodes:game.gdprCountryCodes});
        }
    }

    return(
        <div>
            {(!loading && game.name) ?(
                <>
                    <h5>{game.name}</h5>
                    <ul className="collapsible">
                        <li>
                            <div className="collapsible-header"><i className="material-icons">settings</i>Update Settings</div>
                            <div className="collapsible-body">
                                <div className="row">
                                    <form className="col offset-m3 m6 s12">

                                        <div className="input-field" style={{marginTop:'2rem'}}>
                                            <input placeholder="Enter new game name"
                                                   id="name"
                                                   type="text"
                                                   name="name"
                                                   value={game.name}
                                                   onChange={changeHandler}/>
                                            <label htmlFor="name">Name</label>
                                        </div>
                                        <div className="input-field" style={{marginTop:'2rem'}}>
                                            <input placeholder="New Game IOS Bundle Id"
                                                   id="bundleIdIOS"
                                                   type="text"
                                                   name="bundleIdIOS"
                                                   value={game.bundleIdIOS}
                                                   onChange={changeHandler}/>
                                            <label htmlFor="bundleIdIOS"> IOS Bundle Id</label>
                                        </div>
                                        <div className="input-field" style={{marginTop:'2rem'}}>
                                            <input placeholder="New Game Android Bundle Id"
                                                   id="bundleIdAndroid"
                                                   type="text"
                                                   name="bundleIdAndroid"
                                                   value={game.bundleIdAndroid}
                                                   onChange={changeHandler}/>
                                            <label htmlFor="bundleIdIOS"> IOS Android Id</label>
                                        </div>
                                        <div className="card-action">
                                            <button className="btn orange lighten-1 black-text"
                                                    onClick={updateDataHandler}
                                                    disabled={loading}
                                            >Update</button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="collapsible-header"><i className="material-icons">verified_user</i>Update GDPR Settings</div>
                            <div className="collapsible-body">
                                <div className="row">
                                    <div className="col m6 s12">

                                        <h6>European Union Countries</h6>
                                        <table className="country-list">
                                            <thead>
                                            <tr>
                                                <th>Country</th>
                                                <th>Code</th>
                                                <th>GDPR</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {europeCountries.map((countryRow,index) =>{
                                                return (
                                                    <tr key={countryRow.id}>
                                                        <td>
                                                            {countryRow.country}
                                                        </td>
                                                        <td>
                                                            {countryRow.code}
                                                        </td>

                                                        <td>
                                                            <label>
                                                                <input type="checkbox" checked={isGDPRActivated(countryRow) ? "checked" : ""}
                                                                       onChange={e => {
                                                                           updateGDPRStatus(countryRow,e.target)
                                                                       }} className="filled-in"/>
                                                                <span></span>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="col m6 s12">
                                        <button className="btn orange lighten-1 black-text"
                                                onClick={updateGDPRHandler}
                                                disabled={loading}
                                        >Update</button>
                                    </div>

                                </div>
                            </div>
                        </li>

                    </ul>

                </>
            ):<Loader/>}
        </div>
    )
}
import React, {useState} from 'react';
import {useCallback, useContext, useEffect} from "react";
import {useRemoteConfig} from "../../hooks/remoteConfig.hook";



export const UserGroupConfigOverwrites = ({gameId,groupIndex,currentBatch,setCurrentBatch, remoteConfigsList}) =>
{

    const applyValueOverwrite = (configOverwrite) =>{
        switch (configOverwrite.configType)
        {
            case "bool":
                return configOverwrite.boolNewValue;
                break;
            case "double":
                return configOverwrite.doubleNewValue;
                break;
            case "int":
                return configOverwrite.intNewValue;
                break;
            case "string":
                return configOverwrite.stringNewValue;
                break;
            default: return 0; break;
        }
    }


    const createHandler =async e =>{
        e.preventDefault();

        let newConfig  = {
            remoteConfigId:"",
            configType:"",
            defaultValue:"",

            valueOverwrite:"",

            boolNewValue:false,
            doubleNewValue:0,
            intNewValue:0,
            stringNewValue:"",
        }

        let editedUserGroup = {
            ...currentBatch.userGroups[groupIndex],
            configOverwrites:[
                ...currentBatch.userGroups[groupIndex].configOverwrites,
                newConfig
            ]
        }

        let updatedBatch = {...currentBatch,userGroups:[
                ...currentBatch.userGroups.slice(0, groupIndex),
                editedUserGroup,
                ...currentBatch.userGroups.slice(groupIndex + 1),
            ]}

        setCurrentBatch(updatedBatch);
    }

    const changeHandler = (target,index) =>
    {
        let editedObject = currentBatch.userGroups[groupIndex].configOverwrites[index];
        editedObject[target.name] = target.value;

        editedObject.valueOverwrite =  applyValueOverwrite(editedObject);

        let editedUserGroup = {
            ...currentBatch.userGroups[groupIndex],
            configOverwrites:[
                ...currentBatch.userGroups[groupIndex].configOverwrites.slice(0, index),
                editedObject,
                ...currentBatch.userGroups[groupIndex].configOverwrites.slice(index + 1),
            ]
        }

        let updatedBatch = {...currentBatch,userGroups:[
                ...currentBatch.userGroups.slice(0, groupIndex),
                editedUserGroup,
                ...currentBatch.userGroups.slice(groupIndex + 1),
            ]}

        setCurrentBatch(updatedBatch);

    }

    const removeConfigHandler = (index) =>{

        let editedUserGroup = {
            ...currentBatch.userGroups[groupIndex],
            configOverwrites:[
                ...currentBatch.userGroups[groupIndex].configOverwrites.slice(0, index),
                ...currentBatch.userGroups[groupIndex].configOverwrites.slice(index + 1),
            ]
        }

        let updatedBatch = {...currentBatch,userGroups:[
                ...currentBatch.userGroups.slice(0, groupIndex),
                editedUserGroup,
                ...currentBatch.userGroups.slice(groupIndex + 1),
            ]}

        setCurrentBatch(updatedBatch);

    }

    const intValueChangeHandler = (target,index) =>
    {
        let editedObject = currentBatch.userGroups[groupIndex].configOverwrites[index];
        editedObject[target.name] = Math.floor(target.value);

        editedObject.valueOverwrite =  applyValueOverwrite(editedObject);


        let editedUserGroup = {
            ...currentBatch.userGroups[groupIndex],
            configOverwrites:[
                ...currentBatch.userGroups[groupIndex].configOverwrites.slice(0, index),
                editedObject,
                ...currentBatch.userGroups[groupIndex].configOverwrites.slice(index + 1),
            ]
        }

        let updatedBatch = {...currentBatch,userGroups:[
                ...currentBatch.userGroups.slice(0, groupIndex),
                editedUserGroup,
                ...currentBatch.userGroups.slice(groupIndex + 1),
            ]}

        setCurrentBatch(updatedBatch);

    }
    const boolValueChangeHandler = (target,index) =>
    {
        let editedObject = currentBatch.userGroups[groupIndex].configOverwrites[index];
        editedObject[target.name] = !editedObject[target.name];

        editedObject.valueOverwrite =  applyValueOverwrite(editedObject);


        let editedUserGroup = {
            ...currentBatch.userGroups[groupIndex],
            configOverwrites:[
                ...currentBatch.userGroups[groupIndex].configOverwrites.slice(0, index),
                editedObject,
                ...currentBatch.userGroups[groupIndex].configOverwrites.slice(index + 1),
            ]
        }

        let updatedBatch = {...currentBatch,userGroups:[
                ...currentBatch.userGroups.slice(0, groupIndex),
                editedUserGroup,
                ...currentBatch.userGroups.slice(groupIndex + 1),
            ]}

        setCurrentBatch(updatedBatch);
    }

    const configSelectChangeHandler = (event, index) => {

        let editedObject = currentBatch.userGroups[groupIndex].configOverwrites[index];
        editedObject.remoteConfigId = event.target.value;

        let defaultConfig =  remoteConfigsList.find(c=>c._id === event.target.value);

        editedObject.configType =defaultConfig.configType;
        editedObject.defaultValue =defaultConfig.defaultValue;

        let editedUserGroup = {
            ...currentBatch.userGroups[groupIndex],
            configOverwrites:[
                ...currentBatch.userGroups[groupIndex].configOverwrites.slice(0, index),
                editedObject,
                ...currentBatch.userGroups[groupIndex].configOverwrites.slice(index + 1),
            ]
        }

        let updatedBatch = {...currentBatch,userGroups:[
                ...currentBatch.userGroups.slice(0, groupIndex),
                editedUserGroup,
                ...currentBatch.userGroups.slice(groupIndex + 1),
            ]}

        setCurrentBatch(updatedBatch);
    };




    useEffect(()=>{
        window.M.AutoInit();
        window.M.updateTextFields();
    },[])

    return(
        <>
            <div className="row">
                <div className="section col s12">
                    <table>
                        <thead>
                        <tr>
                            <th>Key</th>
                            <th>Type</th>
                            <th>Default Value</th>
                            <th>Value Overwrite</th>
                            <th>Delete</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentBatch.userGroups[groupIndex].configOverwrites.map(function (configOverwrite, i) {
                            return (
                                <tr>
                                    <td>
                                        <select className="browser-default col s6" value={configOverwrite.remoteConfigId} onChange={(e)=>configSelectChangeHandler(e, i)}>
                                            <option value="-1">Select Config</option>
                                            {remoteConfigsList && remoteConfigsList.length> 0 && remoteConfigsList.map(function(remoteConfig, i){
                                                return (
                                                    <option value={remoteConfig._id}>{remoteConfig.configKey}</option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                    <td>
                                        {configOverwrite.configType}
                                    </td>
                                    <td>
                                        {configOverwrite.defaultValue}
                                    </td>
                                    <td>
                                        <div className="input-field"  style={{display:(configOverwrite.configType==="bool"?"block":"none")}}>
                                            <label className="input-field col">
                                                <input type="checkbox"
                                                       id="boolNewValue"
                                                       name ="boolNewValue"
                                                       checked={configOverwrite.boolNewValue ? "checked" : ""}
                                                       onChange={(e)=>boolValueChangeHandler(e.target,i)}
                                                       className="filled-in"/>
                                                <span>Default Value</span>
                                            </label>

                                        </div>
                                        <div className="input-field"  style={{display:(configOverwrite.configType==="double"?"block":"none")}}>
                                            <input placeholder="Default double Value"
                                                   id="doubleNewValue"
                                                   type="number"
                                                   name="doubleNewValue"
                                                   value={configOverwrite.doubleNewValue}
                                                   onChange={(e)=>changeHandler(e.target,i)}/>
                                            <label htmlFor="doubleNewValue">Default Value</label>
                                        </div>
                                        <div className="input-field"  style={{display:(configOverwrite.configType==="int"?"block":"none")}}>
                                            <input placeholder="Default int Value"
                                                   id="intNewValue"
                                                   type="number"
                                                   name="intNewValue"
                                                   value={configOverwrite.intNewValue}
                                                   onChange={(e)=>intValueChangeHandler(e.target,i)}/>

                                            <label htmlFor="intNewValue">Default Value</label>
                                        </div>
                                        <div className="input-field"  style={{display:(configOverwrite.configType==="string"?"block":"none")}}>
                                            <input placeholder="Default string Value"
                                                   id="stringNewValue"
                                                   type="text"
                                                   name="stringNewValue"
                                                   value={configOverwrite.stringNewValue}
                                                   onChange={(e)=>changeHandler(e.target,i)}/>
                                            <label htmlFor="stringNewValue">Default Value</label>
                                        </div>
                                    </td>
                                    <td>
                                        <button className="btn waves-effect waves-light orange"
                                                onClick={() => removeConfigHandler(i)}
                                        >
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>

            </div>

            <div className="row">
                <div className="col s6">
                    <button className="btn green lighten-1 black-text"
                            onClick={createHandler}
                    >Add Config</button>
                </div>
            </div>

        </>
    )
}
import React, {useContext, useEffect, useState} from 'react';
import 'materialize-css';
import {useParams} from 'react-router-dom';
import {useHttp} from "../hooks/http.hook";
import {useMessage} from "../hooks/message.hook";
import {AuthContext} from "../context/auth.context";
import {useCallback} from "react";
import {BatchesView} from "../components/ABTests/BatchesView";
import {RemoteConfigsView} from "../components/ABTests/RemoteConfigsView";


export const ABTestsPage = () =>{
    let gameId = useParams().id;

    const {userType} = useContext(AuthContext);
    const message = useMessage();
    const {loading,error, request,clearError} = useHttp();


    useEffect(()=>{
        window.M.AutoInit();
        window.M.updateTextFields();
    },[])


    return(
        <div>
            <h5>AB Tests Page</h5>
            <div className="row">
                <div className="col s12 m12">
                    <div className="card">
                        <div className="card-content">
                            <div className="row">
                                <div className="col s12">
                                    <ul className="tabs">
                                        <li className="tab col s3"><a  href="#batches_tab">Batches</a></li>
                                        <li className="tab col s3"><a  href="#remoteconfigs_tab">Remote Configs</a></li>
                                    </ul>
                                </div>
                                <div id="batches_tab" className="col s12">
                                    <BatchesView gameId={gameId} />
                                </div>
                                <div id="remoteconfigs_tab" className="col s12">
                                    <RemoteConfigsView gameId={gameId} />
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}